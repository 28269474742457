@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Regular.ttf');
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Light.ttf');
  font-weight: lighter;
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-SemiBold.ttf');
  font-weight: 500;
}

body {
  font-family: 'LMSans', sans-serif !important;
  touch-action: pan-y !important;
}
* {
  max-width: 1920px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}
*::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
